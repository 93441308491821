import { ButtonProps } from 'antd';
import * as _ from 'lodash';
import { LTLAddressType } from '@/interfaces/ltl-address-type.enum';
import { Quote } from '@/interfaces/quote.interface';
import { ShipmentItem } from '@/interfaces/shipment.interface';
import { Warehouse } from '@/interfaces/warehouse.interface';
import { CreateQuotePropsData, CreateShipmentRes, QuoteRate } from '@/services/apis';
import dayjs, { Dayjs } from 'dayjs';
import { makeAutoObservable, runInAction } from 'mobx';

const initialStepOneFormValuesItem = {
	description: undefined, // 商品描述
	class: undefined, // 货运等级
	units: undefined, // 单位数量
	total_weight: undefined, // 总重量
	weight_unit: 'lbs', // 重量单位
	dimension_unit: 'in', // 尺寸单位
	package_type: 'Pallet', // 包装类型
	pieces: undefined, // 件数
	height: undefined, // 高度
	length: undefined, // 长度
	width: undefined, // 宽度
	nmfc: undefined, // NMFC 代码
} as const;

export const kgToLb = (kg: number) => {
	return kg / 0.4535;
};

export const cm2in = (cm: number) => {
	return Math.ceil(cm * 0.393701);
};

export const cm3ToFt3 = (cm3: number) => {
	return cm3 * 0.00003531;
};

export const in3ToFt3 = (in3: number) => {
	return in3 * 0.0005787;
};

export const freightClasses = [
	{
		threshold: 30,
		classCode: '60',
	},
	{
		threshold: 22.5,
		classCode: '65',
	},
	{
		threshold: 15,
		classCode: '70',
	},
	{
		threshold: 12,
		classCode: '85',
	},
	{
		threshold: 10,
		classCode: '92.5',
	},
	{
		threshold: 8,
		classCode: '100',
	},
	{
		threshold: 6,
		classCode: '125',
	},
	{
		threshold: 4,
		classCode: '175',
	},
	{
		threshold: 2,
		classCode: '250',
	},
	{ threshold: 1, classCode: '300' },
	{
		threshold: 0,
		classCode: '400',
	},
];

export const imperialFreightClass = (lb: number, ft3: number) => {
	const density = lb / ft3;
	for (let i = 0; i < freightClasses.length; i++) {
		if (density >= freightClasses[i].threshold) {
			return freightClasses[i].classCode;
		}
	}

	return '400';
};

export const calcTotalWeight = (items: ShipmentItem[]) => {
	const totalWeight = _.sum(
		items?.map((item) =>
			item.weight_unit === 'lbs'
				? Number(item.total_weight) ?? 0
				: kgToLb(Number(item.total_weight) ?? 0),
		),
	);
	return Number.isNaN(totalWeight) ? 0 : Math.ceil(Number(totalWeight.toFixed(2)));
};

export const calcTotalVolume = (items: ShipmentItem[]) => {
	const sum = _.sum(
		items?.map((item) =>
			item.dimension_unit === 'in'
				? in3ToFt3(item.units * item.height * item.width * item.length)
				: cm3ToFt3(item.units * item.height * item.width * item.length),
		),
	);

	return Number.isNaN(sum) ? 0 : sum.toFixed(2);
};

export const generateNumberOfUnits = (items: ShipmentItem[]) => {
	const map: Record<string, number> = {};

	for (const item of items) {
		if (item.units === undefined) continue;
		if (map[item.package_type]) {
			map[item.package_type] += item.units;
		} else {
			map[item.package_type] = item.units;
		}
	}

	return (
		Object.entries(map)
			?.map(([k, v]) => `${v} ${k}`)
			.join(', ') ?? ''
	);
};

class LTLContext {
	private _step: 1 | 2 | 3 | 4;

	private _stepNextHandler: () => void;

	private _stepNextButtonProps: ButtonProps = {};

	private _stepOneFormValues: Omit<
		CreateQuotePropsData,
		| 'items'
		| 'pickup_date'
		| 'pickup_zipcode'
		| 'destination_zipcode'
		| 'pickup_city_id'
		| 'destination_city_id'
		| 'pickup_address_type'
		| 'destination_address_type'
	> & {
		items: Array<ShipmentItem & { id: string }>;
		pickup_date: Dayjs;
	} = {
		pickup_date: undefined, // 提货日期
		items: [{ ...LTLContext.createStepOneFormValuesItem() }],
		pickup_accessorials: [], // 提货附加服务
		destination_accessorials: [], // 目的地附加服务
		shipment_accessorials: [], // 运输附加服务
	};

	private _stepThreeFormValues: Partial<{
		pickup_open_time: Dayjs;
		pickup_close_time: Dayjs;
		destination_open_time: Dayjs;
		destination_close_time: Dayjs;
		estimated_delivery_date: Dayjs;
		customer_reference_number: string; // required
		pickup_number: string; // required
		pickup_instruction: string; // required
		dropoff_number: string; // required
		dropoff_instruction: string; // required
		special_request?: string; // optional
	}> & { isCheckAcknowledge: boolean } = {
		pickup_open_time: undefined,
		pickup_close_time: undefined,
		destination_open_time: undefined,
		destination_close_time: undefined,
		estimated_delivery_date: undefined,
		customer_reference_number: undefined, // required
		pickup_number: undefined, // required
		pickup_instruction: undefined, // required
		dropoff_number: undefined, // required
		dropoff_instruction: undefined, // required
		special_request: undefined, // optional

		isCheckAcknowledge: false,
	};

	private _selectedQuoteRate?: QuoteRate;

	private _quote?: Quote;

	private _order?: CreateShipmentRes['data'];

	private _pickupAddress?: Warehouse & { address2: string; pickup_special_request?: string };

	private _destinationAddress?: Warehouse & {
		address2: string;
		destination_special_request?: string;
	};

	private _savePickupAddress = false;

	private _saveDestinationAddress = false;

	private _quoteRates: QuoteRate[] = [];

	private _pickUpAddressType: LTLAddressType = LTLAddressType.BUSSINESS;

	private _destinationAddressType: LTLAddressType = LTLAddressType.BUSSINESS;

	constructor() {
		makeAutoObservable(this);

		this.init();
	}

	get step() {
		return this._step;
	}

	set step(value: typeof this._step) {
		runInAction(() => {
			this._step = value;
		});
	}

	get stepNextHandler() {
		return this._stepNextHandler;
	}

	set stepNextHandler(value: typeof this._stepNextHandler) {
		runInAction(() => {
			this._stepNextHandler = value;
		});
	}

	get stepNextButtonProps() {
		return this._stepNextButtonProps;
	}

	set stepNextButtonProps(value: typeof this._stepNextButtonProps) {
		runInAction(() => {
			this._stepNextButtonProps = value;
		});
	}

	get stepOneFormValues() {
		return this._stepOneFormValues;
	}

	set stepOneFormValues(value: typeof this._stepOneFormValues) {
		runInAction(() => {
			this._stepOneFormValues = value;
		});
	}

	get stepThreeFormValues() {
		return this._stepThreeFormValues;
	}

	set stepThreeFormValues(value: typeof this._stepThreeFormValues) {
		runInAction(() => {
			this._stepThreeFormValues = value;
		});
	}

	get selectedQuoteRate() {
		return this._selectedQuoteRate;
	}

	set selectedQuoteRate(value: typeof this._selectedQuoteRate) {
		runInAction(() => {
			this._selectedQuoteRate = value;
		});
	}

	get quote() {
		return this._quote;
	}

	set quote(value: typeof this._quote) {
		runInAction(() => {
			this._quote = value;
		});
	}

	get order() {
		return this._order;
	}

	set order(value: typeof this._order) {
		runInAction(() => {
			this._order = value;
		});
	}

	get pickupAddress() {
		return this._pickupAddress;
	}

	set pickupAddress(value: typeof this._pickupAddress) {
		runInAction(() => {
			this._pickupAddress = value;
		});
	}

	get destinationAddress() {
		return this._destinationAddress;
	}

	set destinationAddress(value: typeof this._destinationAddress) {
		runInAction(() => {
			this._destinationAddress = value;
		});
	}

	get savePickupAddress() {
		return this._savePickupAddress;
	}

	set savePickupAddress(value: typeof this._savePickupAddress) {
		runInAction(() => {
			this._savePickupAddress = value;
		});
	}

	get saveDestinationAddress() {
		return this._saveDestinationAddress;
	}

	set saveDestinationAddress(value: typeof this._saveDestinationAddress) {
		runInAction(() => {
			this._saveDestinationAddress = value;
		});
	}

	get quoteRates() {
		return this._quoteRates;
	}

	set quoteRates(value: typeof this._quoteRates) {
		runInAction(() => {
			this._quoteRates = value;
		});
	}

	static createStepOneFormValuesItem() {
		return { ...initialStepOneFormValuesItem, id: Math.random().toString() };
	}

	get pickUpAddressType() {
		return this._pickUpAddressType;
	}

	set pickUpAddressType(value: typeof this._pickUpAddressType) {
		runInAction(() => {
			this._pickUpAddressType = value;
		});
	}

	get destinationAddressType() {
		return this._destinationAddressType;
	}

	set destinationAddressType(value: typeof this._destinationAddressType) {
		runInAction(() => {
			this._destinationAddressType = value;
		});
	}

	init() {
		this.step = 1;
		this.stepNextButtonProps = {};
		this.stepOneFormValues = {
			pickup_date: undefined, // 提货日期
			items: [{ ...LTLContext.createStepOneFormValuesItem() }],
			pickup_accessorials: [], // 提货附加服务
			destination_accessorials: [], // 目的地附加服务
			shipment_accessorials: [], // 运输附加服务
		};
		this.stepThreeFormValues = {
			pickup_open_time: undefined,
			pickup_close_time: undefined,
			destination_open_time: undefined,
			destination_close_time: undefined,
			estimated_delivery_date: undefined,
			customer_reference_number: undefined, // required
			pickup_number: undefined, // required
			pickup_instruction: undefined, // required
			dropoff_number: undefined, // required
			dropoff_instruction: undefined, // required
			special_request: undefined, // optional

			isCheckAcknowledge: false,
		};
		this.selectedQuoteRate = undefined;
		this.quote = undefined;
		this.order = undefined;
		this.pickupAddress = undefined;
		this.destinationAddress = undefined;
		this.savePickupAddress = false;
		this.saveDestinationAddress = false;
		this.quoteRates = [];
		this.pickUpAddressType = LTLAddressType.BUSSINESS;
		this.destinationAddressType = LTLAddressType.BUSSINESS;
	}

	addStepOneFormValuesItem() {
		runInAction(() => {
			this.stepOneFormValues.items = [
				...this.stepOneFormValues.items,
				LTLContext.createStepOneFormValuesItem(),
			];
		});
	}

	calcItemFreightClass() {
		runInAction(() => {
			this.stepOneFormValues.items = this.stepOneFormValues.items?.map((item) => {
				const lb = item.weight_unit === 'lbs' ? item.total_weight : kgToLb(item.total_weight);
				const volume = item.length * item.width * item.height;
				const ft3 = item.dimension_unit === 'in' ? in3ToFt3(volume) : cm3ToFt3(volume);
				const freightClass = imperialFreightClass(lb, item.units * ft3);
				return {
					...item,
					class: freightClass,
				};
			});
		});
	}

	calcIsLiftGateRequired() {
		return (
			(this.stepOneFormValues.pickup_accessorials.includes('liftgate') ||
				this.stepOneFormValues.destination_accessorials.includes('liftgate')) &&
			this.stepOneFormValues.items.some((item) => {
				const isTooLong =
					item.dimension_unit === 'cm'
						? [item.length, item.width].some((i) => i > 210) || item.height > 239
						: [item.length, item.width].some((i) => i > 83) || item.height > 94;
				const isOverweight =
					item.weight_unit === 'kg'
						? Math.ceil(item.total_weight / item.units) > 907
						: Math.ceil(item.total_weight / item.units) > 1999;
				return isTooLong || isOverweight;
			})
		);
	}
}

export const ltl = new LTLContext();
