import { useTranslation } from 'react-i18next';
import { Form, Radio, Space, TimePicker } from 'antd';
import { ShipmentTimeType } from '@/interfaces/ftl-shipment.interface';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import { observer } from 'mobx-react-lite';
import { disabledEndTime, disabledStartTime } from '@/utils/disabled-time';
import './index.less';

interface TimeWindowProps {
	value: ShipmentTimeType;
	start?: Dayjs;
	end?: Dayjs;
	specific?: Dayjs;
	disabled?: boolean;
}

export const TimeWindow = observer(({ value, start, end, specific, disabled }: TimeWindowProps) => {
	const { t } = useTranslation();

	const isBetween = value === ShipmentTimeType.WINDOW;

	const isSpecific = value === ShipmentTimeType.SPECIFIC;

	return (
		<Form.Item noStyle name='value'>
			<Radio.Group value={ShipmentTimeType.WINDOW} disabled={disabled} className='time-window'>
				<Space direction='vertical'>
					<Radio value={ShipmentTimeType.WINDOW}>
						<div className='flex items-center gap-[8px] whitespace-nowrap'>
							<div>{t(`Choose a window between`)}</div>
							<Form.Item
								name='start'
								rules={[{ required: isBetween }]}
								className={clsx(!isBetween && 'hidden', 'w-[80px]')}
							>
								<TimePicker
									disabled={disabled}
									changeOnScroll
									needConfirm={false}
									format={'HH:mm'}
									suffixIcon={null}
									placeholder=''
									minuteStep={15}
									showNow={false}
									disabledTime={disabledStartTime(end)}
									// className={`${formItemWrapper.className} w-[222px] h-[42px]`}
								/>
							</Form.Item>
							<div>{t(`and`)}</div>
							<Form.Item
								name='end'
								rules={[{ required: isBetween }]}
								className={clsx(!isBetween && 'hidden', 'w-[80px]')}
							>
								<TimePicker
									disabled={disabled}
									changeOnScroll
									needConfirm={false}
									format={'HH:mm'}
									suffixIcon={null}
									placeholder=''
									minuteStep={15}
									showNow={false}
									disabledTime={disabledEndTime(start)}
									// className={`${formItemWrapper.className} w-[222px] h-[42px]`}
								/>
							</Form.Item>
							<div>{t(`(first come, first serve)`)}</div>
						</div>
					</Radio>

					<Radio value={ShipmentTimeType.SPECIFIC}>
						<div className='flex items-center gap-[8px] whitespace-nowrap'>
							<span>{t('Pickup at specific time')}</span>
							<Form.Item
								name='specific'
								rules={[{ required: isSpecific }]}
								className={clsx(!isSpecific && 'hidden', 'w-[80px]')}
							>
								<TimePicker
									disabled={disabled}
									changeOnScroll
									needConfirm={false}
									format={'HH:mm'}
									suffixIcon={null}
									placeholder=''
									minuteStep={15}
									showNow={false}
									// className={`${formItemWrapper.className} w-[222px] h-[42px]`}
								/>
							</Form.Item>
						</div>
					</Radio>

					<Radio value={ShipmentTimeType.BEHALF}>{t('Set appointment on behalf of me')}</Radio>
				</Space>
			</Radio.Group>
		</Form.Item>
	);
});
