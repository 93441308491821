import { useParams } from 'react-router-dom';
import { apiHooks } from '@/hooks/apis';

export const useFtlOrder = () => {
	const { orderId } = useParams();

	const res = apiHooks.useGetFTLShipment({
		defaultParams: [{ path: { id: orderId } }],
		cacheKey: `get-ftl-shipment-${orderId}`,
	});

	return res;
};
