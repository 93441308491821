import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Layout, MenuProps } from 'antd';
import historySvg from '@/assets/history.svg';
import locationSvg from '@/assets/location.svg';
import settingSvg from '@/assets/setting.svg';
import Home24pxIcon from '@/icons/home-24px-icon';
import { userContext } from '@/services/contexts/user';
import {
	NON_PREPAYABLE_ACCOUNT,
	TYPE_DEDICATED,
	TYPE_INSIDER,
	TYPE_NORMAL,
	TYPE_THIRD_PARTY,
} from '@/services/contexts/user/constant';
import { useMemoizedFn } from 'ahooks';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { Logo } from './logo';
import { MenuGroup } from './menu-group';
import { UserInfo } from './user-info';

const { Sider: AntdSider } = Layout;

export const Sider = observer(() => {
	const { i18n, t } = useTranslation();

	const handleChangeLanguage: MenuProps['onClick'] = ({ key }) => {
		i18n.changeLanguage(key);
		window.location.href = '/';
	};

	const __enable_project_feature = !!userContext.getUserInfo()?.__enable_project_feature;

	const __enable_ltl = !!userContext.getUserInfo()?.__enabled_ltl_feature;

	const __enabled_ftl_feature = !!userContext.getUserInfo()?.__enabled_ftl_feature;

	const menu1 = [
		{
			label: 'Drayage',
			key: 'drayage',
			icon: <Home24pxIcon />,
			children: [
				{
					label: t('Search Rate'),
					key: 'search-rate',
					// icon: <img src={searchSvg} />,
					hide: [TYPE_THIRD_PARTY],
				},
				{
					label: t('Rate Request'),
					key: 'rate-request',
					// icon: <img src={historySvg} />,
					hide: [TYPE_THIRD_PARTY],
				},
				{
					label: t('Orders'),
					key: 'orders',
					// icon: <img src={ordersSvg} />,
					hide: [],
				},
				{
					label: t('Wallet'),
					key: 'prepay-orders',
					// icon: <img src={walletSvg} />,
					hide: [TYPE_THIRD_PARTY, NON_PREPAYABLE_ACCOUNT],
				},
				...(__enable_project_feature
					? [
							{
								label: t('Dashboard'),
								key: 'projects',
								// icon: <Home24pxIcon />,
								hide: [],
							},
					  ]
					: []),
			],
		},
		...(__enable_ltl
			? [
					{
						label: 'LTL',
						key: 'ltl',
						icon: <Home24pxIcon />,
						children: [
							{
								label: t('Search Rate'),
								key: 'ltl-search-rate',
								// icon: <img src={searchSvg} />,
								hide: [TYPE_THIRD_PARTY],
							},
							{
								label: t('Quote History'),
								key: 'ltl-quote-history',
								hide: [TYPE_THIRD_PARTY],
							},
							{
								label: t('Orders'),
								key: 'ltl-orders',
								hide: [TYPE_THIRD_PARTY],
							},
						],
					},
			  ]
			: []),
		...(__enabled_ftl_feature
			? [
					{
						label: 'FTL',
						key: 'ftl',
						icon: <Home24pxIcon />,
						children: [
							{
								label: t('Search Rate'),
								key: 'ftl-get-rate',
								// icon: <img src={searchSvg} />,
								hide: [TYPE_THIRD_PARTY],
							},
							{
								label: t('Quote History'),
								key: 'ftl-quote-history',
								hide: [TYPE_THIRD_PARTY],
							},
							{
								label: t('Orders'),
								key: 'ftl-orders',
								// icon: <img src={searchSvg} />,
								hide: [TYPE_THIRD_PARTY],
							},
						],
					},
			  ]
			: []),
	];

	const menu2 = [
		// {
		// 	label: 'Profile',
		// 	key: 'profile',
		// 	icon: <img src={profileSvg} />,
		// },

		{
			label: t('Address'),
			key: 'addresses',
			icon: <img src={locationSvg} />,
		},
	];

	const menu3 = [
		{
			label: t('Agent'),
			key: 'agent',
			icon: <img src={historySvg} />,
			children: [
				{
					label: t('Dray Orders'),
					key: 'agent-dray-orders',
				},
				{
					label: t('LTL Orders'),
					key: 'agent-ltl-orders',
				},
				{
					label: t('Users'),
					key: 'agent-users',
				},
			],
			hide: [TYPE_NORMAL, TYPE_THIRD_PARTY, TYPE_DEDICATED, TYPE_INSIDER],
		},
		{
			label: t('Language'),
			key: 'setting',
			icon: <img src={settingSvg} />,
			children: [
				{
					key: 'en-US',
					label: 'English',
					onClick: handleChangeLanguage,
				},
				{
					key: 'zh-CN',
					label: '中文',
					onClick: handleChangeLanguage,
				},
			],
		},
	];

	const filterHideMenu = useMemoizedFn((menu: any[]) => {
		return menu.filter((m) => {
			const allowed =
				!m.hide ||
				(!m.hide.includes(userContext.type) &&
					(userContext.prepayable || !m.hide.includes(NON_PREPAYABLE_ACCOUNT)));

			if (!allowed) return false;

			if (m.children) {
				m.children = filterHideMenu(m.children);
			}

			return true;
		});
	});

	const _menu1 = useMemo(() => {
		return filterHideMenu(menu1);
	}, [userContext.type, userContext.prepayable]);

	const _menu2 = useMemo(() => {
		return filterHideMenu(menu2);
	}, [userContext.type]);

	const _menu3 = useMemo(() => {
		return filterHideMenu(menu3);
	}, [userContext.type]);

	return (
		<AntdSider width={240} className=' bg-[#003166]'>
			<div className='flex flex-col justify-between h-screen overflow-hidden'>
				<div className='grow overflow-y-scroll w-full flex flex-col'>
					<Gap height='24px' />
					{/* logo */}
					<Logo />

					<Gap height='12px' />

					{/* menu group */}
					<MenuGroup name={t('M A I N M E N U')} items={_menu1} mode='inline' />
					<Gap height='36px' />
					<MenuGroup name={t('P R E F E R E N C E S')} items={_menu2} mode='vertical' />
					<MenuGroup items={_menu3} mode='inline' />
				</div>

				<div className='mb-[36px]'>
					{/* help */}
					{/* <Help /> */}
					{/* user info */}
					<UserInfo />
				</div>
			</div>
		</AntdSider>
	);
});
