import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
	Checkbox,
	DatePicker,
	Divider,
	Form,
	FormInstance,
	Input,
	message,
	TimePicker,
} from 'antd';
import * as _ from 'lodash';
import CircleBlueFillIcon from '@/icons/circle-blue-fill';
import DestinationIcon from '@/icons/destination-icon';
import { QuoteBusinessStatus } from '@/interfaces/quote.interface';
import { CreateShipmentPropsData } from '@/services/apis';
import { useLTLContext } from '@/services/contexts';
import { userContext } from '@/services/contexts/user';
import { useMemoizedFn } from 'ahooks';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import { apiHooks } from '@/hooks/apis';
import { disabledEndTime, disabledStartTime } from '@/utils/disabled-time';
import { formatMMDDYYYYDate } from '@/utils/format-MMDDYYYY-date';
import { FormItem } from '../../form-item';
import { formItemWrapper } from '../../form-item-wrapper';
import { Insurance } from '../insurance';
import {
	ItemTitle,
	ShipmentInfoEditButton,
	ShipmentInfoMap,
	ShipmentInfoPickupDate,
} from '../shipment-info';
import { ShipmentInfoCargoSummary } from '../shipment-info/shipment-info-cargo-summary';
import { ShipmentInfoDestinationAddress } from '../shipment-info/shipment-info-destination-address';
import { ShipmentInfoPickupAddress } from '../shipment-info/shipment-info-pickup-address';
import './index.less';
import { ShipmentDetailItem } from './shipment-detail-item';

const StepThreeForm = observer(({ form }: { form: FormInstance }) => {
	const { t } = useTranslation();

	const ltlContext = useLTLContext();

	// pick up address
	const [form1] = Form.useForm();

	// delivery address
	const [form2] = Form.useForm();

	// pickup window
	const [form3] = Form.useForm();

	// delivery window
	const [form4] = Form.useForm();

	const { runAsync: createShipment } = apiHooks.useCreateShipment();

	const { runAsync: createWarehouseWithCheck } = apiHooks.useCreateWarehouseWithCheck();

	const { runAsync: updateWarehouse } = apiHooks.useUpdateWarehouse();

	const handleNext = useMemoizedFn(async () => {
		try {
			ltlContext.stepNextButtonProps.loading = true;

			if (!userContext.hasEnoughBalance(+ltlContext.selectedQuoteRate.rate)) {
				message.error(t('Insufficient wallet balance'));
				return;
			}

			await Promise.all([
				form.validateFields(),
				form1.validateFields(),
				form2.validateFields(),
				form3.validateFields(),
				form4.validateFields(),
			]);

			const res = await createShipment({
				data: {
					pickup_zipcode: ltlContext.pickupAddress.zipcode, // required
					pickup_city_id: ltlContext.pickupAddress.city.id, // City id 从【获取城市列表】接口中获取 | required
					pickup_company_name: ltlContext.pickupAddress.name, // Facility Name | required
					pickup_contact_name: ltlContext.pickupAddress.contacts?.[0]?.name, // Facility Contact Name | required
					pickup_contact_email: ltlContext.pickupAddress.contacts?.[0]?.email, // Contact Email | required
					pickup_contact_phone: ltlContext.pickupAddress.contacts?.[0]?.phone, // Contact Phone | required
					pickup_address1: ltlContext.pickupAddress.address, // Street Name | required
					pickup_address2: ltlContext.pickupAddress.address2, // Apt, Suite | optional
					pickup_special_request: ltlContext.pickupAddress.special_request,
					pickup_address_type: ltlContext.pickUpAddressType,
					destination_zipcode: ltlContext.destinationAddress.zipcode, // required
					destination_city_id: ltlContext.destinationAddress.city.id, // City id 从【获取城市列表】接口中获取 | required
					destination_state: ltlContext.destinationAddress.city.state,
					destination_company_name: ltlContext.destinationAddress.name, // Facility Name | required
					destination_contact_name: ltlContext.destinationAddress.contacts?.[0]?.name, // Facility Contact Name | required
					destination_contact_email: ltlContext.destinationAddress.contacts?.[0]?.email, // Contact Email | required
					destination_contact_phone: ltlContext.destinationAddress.contacts?.[0]?.phone, // Contact Phone | required
					destination_address1: ltlContext.destinationAddress.address, // Street Name | required
					destination_address2: ltlContext.destinationAddress.address2, // Apt, Suite | optional
					destination_special_request: ltlContext.destinationAddress.special_request,
					destination_address_type: ltlContext.destinationAddressType,

					estimated_pickup_date: dayjs(ltlContext.stepOneFormValues.pickup_date).format(
						'YYYY-MM-DD',
					),

					...ltlContext.stepThreeFormValues,
					pickup_open_time: dayjs(ltlContext.stepThreeFormValues.pickup_open_time).format('HH:mm'),
					pickup_close_time: dayjs(ltlContext.stepThreeFormValues.pickup_close_time).format(
						'HH:mm',
					),
					destination_open_time: dayjs(ltlContext.stepThreeFormValues.destination_open_time).format(
						'HH:mm',
					),
					destination_close_time: dayjs(
						ltlContext.stepThreeFormValues.destination_close_time,
					).format('HH:mm'),

					items: ltlContext.stepOneFormValues.items.map((item) => {
						const newItem = { ...item };
						delete item['id'];
						return newItem;
					}),

					pickup_accessorials: ltlContext.stepOneFormValues.pickup_accessorials ?? [],
					destination_accessorials: ltlContext.stepOneFormValues.destination_accessorials ?? [],
					shipment_accessorials: ltlContext.stepOneFormValues.shipment_accessorials,

					quote_id: ltlContext.quote?.id,
					rate_id: ltlContext.selectedQuoteRate.id,
				} as CreateShipmentPropsData,
			});

			Promise.allSettled([
				ltlContext.savePickupAddress &&
					Number.isInteger(ltlContext.pickupAddress.id) &&
					(ltlContext.pickupAddress.id === -1
						? createWarehouseWithCheck({
								data: {
									name: ltlContext.pickupAddress.name,
									city_id: ltlContext.pickupAddress.city.id,
									zipcode: ltlContext.pickupAddress.zipcode,
									address: ltlContext.pickupAddress.address,
									address2: ltlContext.pickupAddress.address2,
									special_request: ltlContext.pickupAddress.special_request,
									contacts: ltlContext.pickupAddress.contacts,
									appointment_by: 0,
								},
						  })
						: updateWarehouse({
								path: {
									warehouseId: ltlContext.pickupAddress.id,
								},
								data: {
									name: ltlContext.pickupAddress.name,
									city_id: ltlContext.pickupAddress.city.id,
									zipcode: ltlContext.pickupAddress.zipcode,
									address: ltlContext.pickupAddress.address,
									contacts: ltlContext.pickupAddress.contacts,
									appointment_by: 0,
								},
						  })),
				ltlContext.saveDestinationAddress &&
					Number.isInteger(ltlContext.destinationAddress.id) &&
					(ltlContext.destinationAddress.id === -1
						? createWarehouseWithCheck({
								data: {
									name: ltlContext.destinationAddress.name,
									city_id: ltlContext.destinationAddress.city.id,
									zipcode: ltlContext.destinationAddress.zipcode,
									address: ltlContext.destinationAddress.address,
									address2: ltlContext.destinationAddress.address2,
									special_request: ltlContext.destinationAddress.special_request,
									contacts: ltlContext.destinationAddress.contacts,
									appointment_by: 0,
								},
						  })
						: updateWarehouse({
								path: {
									warehouseId: ltlContext.destinationAddress.id,
								},
								data: {
									name: ltlContext.destinationAddress.name,
									city_id: ltlContext.destinationAddress.city.id,
									zipcode: ltlContext.destinationAddress.zipcode,
									address: ltlContext.destinationAddress.address,
									contacts: ltlContext.destinationAddress.contacts,
									appointment_by: 0,
								},
						  })),
			]).catch((error) => {
				console.error(`save address error`, error);
			});

			ltlContext.order = res.data.data;
			ltlContext.step = 4;
		} catch (error) {
			console.error(`ltl step three handleNext error`, error);
		} finally {
			ltlContext.stepNextButtonProps.loading = false;
		}
	});

	useEffect(() => {
		ltlContext.stepNextHandler = () => handleNext();
	}, [form, handleNext]);

	useEffect(() => {
		form1.setFieldsValue({
			...ltlContext.pickupAddress,
		});
	}, [ltlContext.pickupAddress]);

	useEffect(() => {
		form2.setFieldsValue({
			...ltlContext.destinationAddress,
		});
	}, [ltlContext.destinationAddress]);

	useEffect(() => {
		form3.setFieldsValue({
			pickup_open_time: ltlContext.stepThreeFormValues.pickup_open_time,
			pickup_close_time: ltlContext.stepThreeFormValues.pickup_close_time,
		});
	}, [
		ltlContext.stepThreeFormValues.pickup_open_time,
		ltlContext.stepThreeFormValues.pickup_close_time,
	]);

	useEffect(() => {
		form4.setFieldsValue({
			destination_open_time: ltlContext.stepThreeFormValues.destination_open_time,
			destination_close_time: ltlContext.stepThreeFormValues.destination_close_time,
		});
	}, [
		ltlContext.stepThreeFormValues.destination_open_time,
		ltlContext.stepThreeFormValues.destination_close_time,
	]);

	useEffect(() => {
		form1.setFieldsValue({
			...ltlContext.pickupAddress,
		});
	}, [ltlContext.pickupAddress]);

	useEffect(() => {
		ltlContext.stepNextButtonProps.disabled = false;
	}, []);

	useEffect(() => {
		ltlContext.stepThreeFormValues.estimated_delivery_date = ltlContext.selectedQuoteRate
			?.estimated_delivery_date
			? dayjs(ltlContext.selectedQuoteRate.estimated_delivery_date)
			: undefined;
	}, [ltlContext.selectedQuoteRate?.estimated_delivery_date]);

	const icon1Ref = useRef<HTMLDivElement>(null);

	const icon2Ref = useRef<HTMLDivElement>(null);

	const [startPoint, setStartPoint] = useState([0, 0]);

	const [length, setLength] = useState(0);

	const handleResize = useMemoizedFn(() => {
		const icon1ClientRects = icon1Ref.current.getClientRects();
		const x = 35;
		const y = 245;
		setStartPoint([x, y]);
		setLength(icon1ClientRects[0].height);
	});

	useLayoutEffect(() => {
		setTimeout(() => {
			handleResize();
		}, 0);

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return (
		<>
			{/* title */}
			<div className='h3-b text-[#333333]'>{t(`Finalize shipment details`)}</div>

			{/* content */}
			<div className='flex justify-between'>
				{/* form */}
				<div className='w-[710px] flex flex-col'>
					{/* title */}
					<div className='pl-[12px]'>
						<Divider />
						<div className='h4-b text-[#333333]'>{t(`Shipment details`)}</div>
					</div>

					<Gap height='16px' />

					<Form
						form={form1}
						validateMessages={{
							required: t(`Required`),
						}}
						onValuesChange={(changedValues, allValues) => {
							_.merge(ltlContext.pickupAddress, changedValues);
						}}
					>
						<ShipmentDetailItem
							type='pickup'
							titleIcon={<CircleBlueFillIcon />}
							borderColor='#C8E3FF'
							extra={
								<div className='flex items-center gap-[12px]'>
									<div className='flex gap-[12px]'>
										<FormItem titleProps={{ content: t('Pickup Date'), required: true }}>
											<Input
												disabled
												value={
													ltlContext.stepOneFormValues.pickup_date
														? ltlContext.stepOneFormValues.pickup_date.format('MM/DD/YYYY')
														: ''
												}
												style={formItemWrapper.style}
												className={`${formItemWrapper.className} w-[222px] h-[42px]`}
											/>
										</FormItem>
									</div>

									<Form
										form={form3}
										onValuesChange={(changedValues, allValues) => {
											_.merge(ltlContext.stepThreeFormValues, changedValues);
										}}
										validateMessages={{
											required: `Required`,
										}}
										className='flex gap-[12px]'
									>
										<FormItem titleProps={{ content: t('Pickup Window'), required: true }}>
											<div className='flex items-center gap-[12px]'>
												<Form.Item name='pickup_open_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Pickup open time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledStartTime(
															ltlContext.stepThreeFormValues.pickup_close_time,
														)}
														className={`${formItemWrapper.className} w-[222px] h-[42px]`}
													/>
												</Form.Item>
												<Form.Item name='pickup_close_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Pickup close time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledEndTime(
															ltlContext.stepThreeFormValues.pickup_open_time,
														)}
														className={`${formItemWrapper.className} w-[222px] h-[42px]`}
													/>
												</Form.Item>
											</div>
										</FormItem>
									</Form>
								</div>
							}
						/>
					</Form>

					<Gap height='24px' />
					<Form
						form={form2}
						validateMessages={{
							required: t(`Required`),
						}}
						onValuesChange={(changedValues, allValues) => {
							_.merge(ltlContext.destinationAddress, changedValues);
						}}
					>
						<ShipmentDetailItem
							type='destination'
							titleIcon={<DestinationIcon />}
							borderColor='#FBBAB8'
							extra={
								<div className='flex items-center gap-[12px]'>
									<div className='flex gap-[12px]'>
										<FormItem titleProps={{ content: t('Delivery Date'), required: true }}>
											<DatePicker
												disabled={!!ltlContext.stepThreeFormValues.estimated_delivery_date}
												suffixIcon={null}
												value={ltlContext.stepThreeFormValues.estimated_delivery_date}
												placeholder={t(`Delivery date`)}
												className={`${formItemWrapper.className} w-[222px] h-[42px]`}
												onChange={(value) => {
													ltlContext.stepThreeFormValues.estimated_delivery_date = value;
												}}
											/>
										</FormItem>
									</div>

									<Form
										form={form4}
										onValuesChange={(changedValues, allValues) => {
											_.merge(ltlContext.stepThreeFormValues, changedValues);
										}}
										validateMessages={{
											required: `Required`,
										}}
										className='flex gap-[12px]'
									>
										<FormItem titleProps={{ content: t('Delivery Window'), required: true }}>
											<div className='flex items-center gap-[12px]'>
												<Form.Item name='destination_open_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Delivery open time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledStartTime(
															ltlContext.stepThreeFormValues.destination_close_time,
														)}
														className={`${formItemWrapper.className} w-[222px] h-[42px]`}
													/>
												</Form.Item>
												<Form.Item name='destination_close_time' rules={[{ required: true }]}>
													<TimePicker
														changeOnScroll
														needConfirm={false}
														format={'HH:mm'}
														suffixIcon={null}
														placeholder={t(`Delivery close time`)}
														minuteStep={15}
														showNow={false}
														disabledTime={disabledEndTime(
															ltlContext.stepThreeFormValues.destination_open_time,
														)}
														className={`${formItemWrapper.className} w-[222px] h-[42px]`}
													/>
												</Form.Item>
											</div>
										</FormItem>
									</Form>
								</div>
							}
						/>
					</Form>

					<div className='pl-[12px] flex flex-col gap-[24px]'>
						{/* title */}
						<div>
							<Divider />
							<div className='h4-b text-[#333333]'>{t(`Reference numbers & instructions`)}</div>
						</div>
						<div className='flex flex-row gap-[12px]'>
							<div className='flex gap-[12px]'>
								<FormItem titleProps={{ content: t('Customer Reference Number') }}>
									<Form.Item name='customer_reference_number'>
										<Input
											maxLength={255}
											style={formItemWrapper.style}
											className={`${formItemWrapper.className} w-[222px] h-[42px]`}
										/>
									</Form.Item>
								</FormItem>
							</div>
							<FormItem titleProps={{ content: t('Pickup Number') }}>
								<Form.Item name='pickup_number'>
									<Input
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[222px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
							<FormItem titleProps={{ content: t('Droppff Number') }}>
								<Form.Item name='dropoff_number'>
									<Input
										maxLength={255}
										style={formItemWrapper.style}
										className={`${formItemWrapper.className} w-[222px] h-[42px]`}
									/>
								</Form.Item>
							</FormItem>
						</div>
					</div>

					<Gap height='24px' />

					<Divider />

					<div className='w-[486px]'>
						<Insurance />
					</div>

					<div className='flex flex-col gap-[24px] acknowledgement'>
						<div>
							<Divider />
							<div className='h4-b text-[#333333]'>
								{t(`Acknowledgement`)}
								<span className=' text-additional-warning'>*</span>
							</div>
						</div>

						<div className='flex items-start gap-[8px]'>
							<Form.Item
								name='isCheckAcknowledge'
								rules={[{ required: true }]}
								valuePropName='checked'
							>
								<Checkbox className={clsx(`red-check-box`)}>
									<div className='h-[16px] body-3-m text-additional-warning'>
										{t(
											`By checking this box, I acknowledge that I have reviewed the information above and accept full responsibility for any expenses incurred due to incorrect measurements.`,
										)}
									</div>
								</Checkbox>
							</Form.Item>
						</div>
					</div>
				</div>

				{/* side map */}
				<div className='relative box-border grow-0 overflow-hidden w-[382px] h-fit border-[1px] border-solid border-grey-05'>
					<div className='w-[full] h-[180px]'>
						<ShipmentInfoMap />
					</div>

					<div className=' box-border p-[24px_24px_36px_24px] w-full'>
						<div className='flex flex-col'>
							<div className='w-full justify-between flex items-center gap-[24px]'>
								<ShipmentInfoPickupDate />
								{ltlContext.quote.business_status === QuoteBusinessStatus.SUBMITED && (
									<ShipmentInfoEditButton />
								)}
							</div>

							<Gap height='4px' />

							<div
								style={{
									position: 'absolute',
									left: `${startPoint[0]}px`,
									top: `${startPoint[1]}px`,
									height: `${length}px`,
									border: '1px dashed grey',
								}}
							/>

							<div className='w-full flex gap-[12px]'>
								<div ref={icon1Ref} className=' box-border pt-[4px]'>
									<CircleBlueFillIcon className='relative z-1 bg-white' />
								</div>
								<div className='grow flex flex-col'>
									<ShipmentInfoPickupAddress />
									<Divider />
								</div>
							</div>

							<div className='w-full flex gap-[12px]'>
								<div ref={icon2Ref} className=' box-border pt-[4px]'>
									<DestinationIcon />
								</div>
								<div className='grow flex flex-col'>
									<ShipmentInfoDestinationAddress />
									<Divider />
								</div>
							</div>

							<ShipmentInfoCargoSummary />

							<Divider />

							<div>
								<div>
									<ItemTitle
										content={t(`Carrier`)}
										value={ltlContext.selectedQuoteRate.carrier_name}
									/>
								</div>
								<Gap height='8px' />
								<div
									className=' box-border p-[4px_10px] w-fit border-[1px] border-solid border-grey-01 rounded-[4px] body-4-sb text-grey-01 flex justify-center items-center cursor-pointer'
									onClick={() => {
										ltlContext.step = 2;
									}}
								>
									{t(`Change Carrier`)}
								</div>
								<Gap height='16px' />
								<div className='grid grid-cols-[auto_auto] grid-rows-[auto_auto] gap-x-[36px] gap-y-[16px]'>
									<div>
										<ItemTitle
											content={t(`Estimated Delivery`)}
											value={
												ltlContext.selectedQuoteRate.estimated_delivery_date
													? formatMMDDYYYYDate(ltlContext.selectedQuoteRate.estimated_delivery_date)
													: ''
											}
										/>
									</div>
									<div>
										<ItemTitle
											content={t(`Transit Time`)}
											value={t(`${ltlContext.selectedQuoteRate?.transit_days ?? '--'} days`)}
										/>
									</div>
									<div>
										<ItemTitle
											content={t(`Base Price`)}
											value={t(`$${ltlContext.selectedQuoteRate.rate}`)}
										/>
									</div>
									{/* <div>
									<ItemTitle content={t(`Extra Insurance`)} value='---' />
								</div> */}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
});

export const StepThree = observer(() => {
	const [form] = Form.useForm();

	const ltlContext = useLTLContext();

	return (
		<Form
			form={form}
			validateMessages={{
				required: `Required`,
			}}
			className='box-border m-auto p-[48px_6px] w-full h-full flex flex-col overflow-x-hidden overflow-y-scroll step-form ltl-step-three'
			onValuesChange={(changedValues, allValues) => {
				_.merge(ltlContext.stepThreeFormValues, changedValues);
				// ltlContext.stepThreeFormValues = {
				// 	...ltlContext.stepThreeFormValues,
				// 	estimated_delivery_date: allValues.estimated_delivery_date,
				// 	customer_reference_number: allValues.customer_reference_number, // required
				// 	pickup_number: allValues.pickup_number, // required
				// 	pickup_instruction: allValues.pickup_instruction, // required
				// 	dropoff_number: allValues.dropoff_number, // required
				// 	dropoff_instruction: allValues.dropoff_instruction, // required
				// 	special_request: allValues.special_request, // optional
				// };
			}}
		>
			<StepThreeForm form={form} />
		</Form>
	);
});
