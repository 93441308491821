import { City } from './city.interface';

export enum ShipmentTimeType {
	WINDOW,
	SPECIFIC,
	BEHALF,
}

export interface FTLShipment {
	id: number;
	uid: string;
	pickup_company_name: string;
	pickup_contact_name: string;
	pickup_contact_phone: string;
	pickup_contact_email: string;
	pickup_zipcode: string;
	pickup_city: City;
	pickup_address1: string;
	pickup_address2?: string | null;
	pickup_special_request?: string | null;
	destination_company_name: string;
	destination_contact_name: string;
	destination_contact_phone: string;
	destination_contact_email: string;
	destination_zipcode: string;
	destination_city: City;
	destination_address1: string;
	destination_address2?: string | null;
	destination_special_request?: string | null;
	pro_number?: string | null;
	customer_reference_number: string;
	pickup_number: string;
	dropoff_number: string;
	business_status: number;
	sell_rate: number;
	pickup_time_type: ShipmentTimeType;
	destination_time_type: ShipmentTimeType;
	pickup_open_time?: string; // "08:00",
	destination_open_time?: string; // "08:00",
	pickup_close_time?: string; // "21:00",
	destination_close_time?: string; // "21:00",
	pickup_specific_time?: string; // "10:00",
	destination_specific_time?: string; // "10:00"
	estimated_pickup_date: string; // Consider Date if handling dates
	estimated_delivery_date: string; // Consider Date if handling dates
	actual_pickup_date?: string | null;
	actual_delivery_date?: string | null;
	status: number;
	tracking_histories: any[]; // Define a more specific type if needed
	verification_status: number;
	user_id: number;
	quote_id: number;
	rate_id: number;
	vendor_shipment_id?: string | null;
	vendor_tracking_url?: string | null;
	vendor_status?: string | null;
	quote_transit_days: number;
	vendor_documents: {
		id: number;
		name: string;
		file_name: string;
		type: string;
		url: string;
	}[]; //
	create_date: string; // Consider Date if handling dates

	commodity_name: string;
	total_units: number;
	package_type: string;
	total_weight: number;
	default_bol_file_name: string;
}
