import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { Button, Table } from 'antd';
import { ColumnsType, TableProps } from 'antd/es/table';
import * as _ from 'lodash';
import { Quote, quoteBusinessStatusMap } from '@/interfaces/quote.interface';
import {
	calcTotalVolume,
	calcTotalWeight,
	generateNumberOfUnits,
} from '@/services/contexts/ltl/ltl.context';
import { useMemoizedFn, useMount } from 'ahooks';
import dayjs from 'dayjs';
import { observer } from 'mobx-react-lite';
import { FT3 } from '@/components/ft3';
import Panel from '@/components/panel';
import { apiHooks } from '@/hooks/apis';
import { ArrElement } from '@/utils/array-element.type';
import { LTLSearchRate } from '../ltl-search-rate';
import useLtlQuoteHistoryParams from './use-ltl-quote-history-params';

export default observer(() => {
	const { t } = useTranslation();

	const navigate = useNavigate();

	const { parsedSearchParams, changeSearchParams } = useLtlQuoteHistoryParams();

	const {
		runAsync: getQuoteList,
		data: quoteList,
		loading: quoteListLoading,
		pagination,
	} = apiHooks.useGetQuoteList();

	const { current, total, pageSize, onChange: changePagination } = pagination;

	const quoteColumns: ColumnsType<Quote> = [
		{
			title: t('Quote#'),
			dataIndex: 'uid',
		},
		{
			title: t('Origin'),
			dataIndex: ['pickup_zipcode'],

			render(date: string, record) {
				return (
					<div>
						{record.pickup_zipcode && <div>{record.pickup_zipcode}</div>}
						<div>
							<span>{record.pickup_city_name ?? ''}</span>
							{record.pickup_city_name && record.pickup_state_name && <span>{`, `}</span>}
							<span>{record.pickup_state_name}</span>
						</div>
					</div>
				);
			},
		},
		{
			title: t('Destination'),
			dataIndex: ['destination_zipcode'],
			render(date: string, record) {
				return (
					<div>
						{record.destination_zipcode && <div>{record.destination_zipcode}</div>}
						<div>
							<span>{record.destination_city_name ?? ''}</span>
							{record.destination_city_name && record.destination_state_name && <span>{`, `}</span>}
							<span>{record.destination_state_name}</span>
						</div>
					</div>
				);
			},
		},
		{
			title: t('Pallets/Volume/Weight'),
			dataIndex: ['items'],

			render(items: ArrElement<Quote['items']>[]) {
				return (
					<div>
						<div>{`${generateNumberOfUnits(items)}`}</div>
						<div>
							<span>{`${calcTotalVolume(items)} `}</span>
							<FT3 />
							<span>{`${calcTotalWeight(items)} lbs`}</span>
						</div>
					</div>
				);
			},
		},
		{
			title: t('Status'),
			dataIndex: 'business_status',

			render(data) {
				return <div>{t(quoteBusinessStatusMap[data]) ?? ''}</div>;
			},
		},
		{
			title: t('Quote Date'),
			dataIndex: ['quote_at'],

			render(date: string) {
				return <div>{date ? dayjs(date).format('MMMM D, YYYY') : ''}</div>;
			},
		},
		{
			title: t(` `),
			render: (_, record) => (
				<div className='flex items-center gap-[4px]'>
					<Button
						type='link'
						onClick={() => {
							changeSearchParams({ ...parsedSearchParams, quote_id: record.id });
						}}
					>
						<span className=' cursor-pointer'>{'View'}</span>
					</Button>
					<Button
						type='link'
						onClick={() => {
							navigate(`/ltl-search-rate?quote_id=${record.id}&type=copy`);
						}}
					>
						<span className=' cursor-pointer'>{'Copy'}</span>
					</Button>
				</div>
			),
		},
	];

	const handleChange: TableProps<Quote>['onChange'] = useMemoizedFn((pagination) => {
		const { current, pageSize } = pagination;
		changePagination(current, pageSize);

		changeSearchParams({
			page: current,
			per_page: pageSize,
		});
	});

	useMount(() => {
		changePagination(parsedSearchParams.page, parsedSearchParams.per_page);
		getQuoteList(
			{ pageSize: parsedSearchParams.per_page, current: parsedSearchParams.page },
			{ params: {} },
		);
	});

	if (parsedSearchParams.quote_id) {
		return <LTLSearchRate />;
	}

	return (
		<div className=' box-border p-[51px_40px_0_40px] h-screen flex flex-col gap-[12px]'>
			<div className='flex justify-between items-center'>
				<div className='h3-b'>{t('LTL Quote History')}</div>
			</div>

			<Panel classNames='h-[calc(100%_-_47px)]'>
				<div className='h-full'>
					<Table
						columns={quoteColumns}
						dataSource={quoteList}
						loading={quoteListLoading}
						className='auto-h-full-table  custom-table'
						pagination={{
							position: ['bottomCenter'],
							total,
							current,
							pageSize,
						}}
						rowClassName='cursor-pointer'
						onChange={handleChange}
					/>
				</div>
			</Panel>
		</div>
	);
});
