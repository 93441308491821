import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';
import { observer } from 'mobx-react-lite';
import { Gap } from '@/components/gap';
import BackButton from '../order-detail/back-button';
import { ShipmentContent } from './shipment-content';
import { SideShipmentInfo } from './side-shipment-info';
import { useFtlOrder } from './use-ftl-order';

export default observer(() => {
	const { t } = useTranslation();

	const { loading: getFTLShipmentDataLoading } = useFtlOrder();

	if (getFTLShipmentDataLoading)
		return (
			<div className='w-full h-full flex justify-center items-center'>
				<Spin />
			</div>
		);

	return (
		<div className='box-border p-[48px] h-screen w-full flex flex-col overflow-x-hidden overflow-y-scroll bg-white'>
			<div className='flex justify-between'>
				<div className='h3-b text-[#333333]'>{t(`Review shipment details`)}</div>
				<BackButton />
			</div>

			<Gap height='35px' />

			<div className='flex justify-between'>
				{/* content */}
				<ShipmentContent />

				{/* side shipment info */}
				<SideShipmentInfo />
			</div>
		</div>
	);
});
